p{
    margin-top: 0;
}

h1{
    background: linear-gradient(to right,  rgba(100,100,100,1) 0%,rgba(0,0,0,0.1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    padding: 5px;
    color: transparent;
    text-shadow: 0px 2px 3px rgba(255,255,255,0.5);
    background-clip: text;
    margin-bottom: 5px;
}

#mainHeader {
    background-color: #282c34;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
    padding: 20px 0;
}
#mainBody{
    width: 96%;
    max-width: 1200px;
    margin: 50px auto 0;
}
#itemWrap, #itemDisplay {
    display: inline-block;
    vertical-align: top;
    box-sizing: border-box;
    height: 100%;
}
#itemWrap {   width: 58%; border-right: 1px dotted #CCC; padding-right: 5px; margin-right: 2%;}
#itemDisplay{ width: 40%; }

#itemWrap .oneProject {
    position: relative;
    display: inline-block;
    width: 48%;
    margin: 0 1% 2%;
    border: 1px solid #CCC;
    box-sizing: border-box;
    cursor: pointer;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
}
#itemWrap .oneProject >div{
    position: absolute;
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    background: rgba(255,255,255, 0.9);
    padding: 3px 10px;
    border-top: 1px solid rgba(0,0,0, 0.1);
    font-weight: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
#itemWrap .oneProject >div h3{
    margin: 0;
    font-weight: normal;
}
#itemWrap .oneProject >div small{
    color: #666;
    display: block;
    margin-top: -3px;
}



#AP_preview{
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 2px 2px 10px rgba(0,0,0,0.3);
}
#AP_linkWrap{
    margin-top: 2px;
}
#AP_linkWrap a{
    color: #666;
    text-decoration: none;
    border-bottom: 1px dotted #666;
    font-size: 0.8em;
}

#AP_content p{
    display: inline-block;
}